<template>
  <div class="">
    <div class="">
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section
              class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3"
            >
              <div class="row profile-body ">
                <div class="col-md-12">
                  <div class="card table-head Merchandise pt-3">


                    <div class="row bor-bot pb-3">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pl-3 pb-2">Summary Reports</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                      <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                      <li class="breadcrumb-item"><Breadcrumbs/></li>
                      </ol>
                      </div> 
                    </div>
                  </div>


                 
                    <div class="card-body pt-0 table-responsive  sumreport mt-3">
                      <table
                        role="table"
                        aria-busy="false"
                        aria-colcount="2"
                        class="table b-table table-hover"
                        id="__BVID__93"
                      >
                       
                        <thead role="rowgroup" class="">
                          <!---->
                          <tr role="row" class="">
                            <th
                              role="columnheader"
                              scope="col"
                              aria-colindex="1"
                              class=""
                            >
                              <div>Report</div>
                            </th>
                            <th
                              role="columnheader"
                              scope="col"
                              aria-colindex="2"
                              class=""
                            >
                              <div>Action</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody role="rowgroup">
                          <tr role="row" class="">
                            <td aria-colindex="1" role="cell" class="">
                              {{ summaryReports.discipline.desc }}
                            </td>
                            <td aria-colindex="2" role="cell" class="">
                              <button class="btn btn-info">
                                <router-link to="/admin/reports/summary/disciplines">
                                  <i class="fas fa-eye"></i>
                                </router-link>
                              </button>
                            </td>
                          </tr>
                          <tr role="row" class="">
                            <td aria-colindex="1" role="cell" class="">
                              {{ summaryReports.membership.desc }}
                            </td>
                            <td aria-colindex="2" role="cell" class="">
                              <button class="btn btn-info">
                                  <!-- <a href="/admin/report/summary-report-view/SummaryByReportingGroupAndType">
                                <i class="fas fa-eye"></i>
                                </a> -->
                                <router-link to="/admin/reports/summary/memberships">
                                  <i class="fas fa-eye"></i>
                                </router-link>
                              </button>
                            </td>
                          </tr>
                          <!-- <tr role="row" class="">
                            <td aria-colindex="1" role="cell" class="">
                              Membership Count Summary by Reporting Group
                            </td>
                            <td aria-colindex="2" role="cell" class="">
                              <button class="btn btn-info">
                                  <a href="/admin/report/summary-report-view/SummaryByReportingGroup">
                                <i class="fas fa-eye"></i>
                                </a>
                              </button>
                            </td>
                          </tr> -->
                          <tr role="row" class="">
                            <td aria-colindex="1" role="cell" class="">
                              {{ summaryReports.financial.desc }}
                            </td>
                            <td aria-colindex="2" role="cell" class="">
                              <button class="btn btn-info">
                                <router-link to="/admin/reports/summary/financials">
                                  <i class="fas fa-eye"></i>
                                </router-link>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table> 
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "summaryreport",
  data() {
    return {
      items: [
        { Report: "Membership Count Summary", Action: "" },
        {
          Report: "Membership Count Summary by Reporting Group and Type",
          Action: "",
        },
        { Report: "Membership Count Summary by Reporting Group", Action: "" },
      ],
      summaryReports:{},
    };
  },
  mounted() {        
        const retry = setInterval(() => {
            this.summaryReports = this.getSiteProps('summaryReports.groupedBy');              
            if(this.summaryReports != "") clearInterval(retry);            
        }, 200);             
  }
};
</script>
